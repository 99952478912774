import { Icons } from "assets/icons";
import { Button, SText } from "components";
import { useHover } from "hooks";
import { useState } from "react";
import { ActivityIndicator, Dimensions, Platform, Pressable, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";


interface moduleProps {
    title: string
    underTitle?: string
    text: any
    buttonValue?: any
    buttonAction?: any
    disabled?: boolean
    warning?: boolean
    deletable?: boolean
    onDelete?: () => void
}

export default ({ title, underTitle, text, buttonValue, buttonAction, disabled, warning, deletable, onDelete }: moduleProps) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();
    const [hovered, hoverElt] = useHover();
    const [loading, setLoading] = useState(false);
    const disableModule = disabled && !(Platform.OS == 'web' && hovered);
    const native = Platform.OS == 'android' || Platform.OS == 'ios' || Dimensions.get("window").width < 768;
    const [deleting, setDeleting] = useState(false);

    const handleOpen = () => {
        if (buttonAction){
            setLoading(true);
            buttonAction()
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <View ref={hoverElt} style={{ width: '100%', flex: 1 }}>

            <View style={[scopedStyles('form')]}>

                {warning &&
                    <View style={{ position: "absolute", top: 13, right: 8, zIndex: 1 }}>
                        <Icons.Warning size={18} color={colors.error} />
                    </View>
                }

                <View style={[scopedStyles('decoration'), { backgroundColor: (disableModule) ? colors.grey : colors.action }]}></View>

                <SText primary md semibold grey={(disableModule)} style={scopedStyles('title')}>{title}</SText>
                {underTitle && <SText md semibold grey style={scopedStyles('underTitle')}>{underTitle}</SText>}

                <View style={[scopedStyles('text'), disableModule && { opacity: 0.5 }]}>
                    {text}
                </View>

                {buttonValue &&
                    <View style={{ flexDirection: 'row', justifyContent: deletable ? "space-between" : "flex-end" }}>
                        {deletable &&
                            <Pressable
                                onPress={() => {
                                    setDeleting(true);
                                    if (onDelete) onDelete()
                                }}
                                style={[
                                    {
                                        padding: 8,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    },
                                    Platform.OS === 'web' && { cursor: 'pointer' }
                                ]}
                            >
                                {deleting ? (
                                    <ActivityIndicator size={18} color={colors.error} />
                                ) : (
                                    <Icons.Delete_trash size={18} color={colors.error} />
                                )}
                            </Pressable>
                        }
                        <Button
                            loading={loading}
                            label={buttonValue}
                            type={disableModule && native ? 'outline' : "action"}
                            disabled={disableModule && !native}
                            onPress={handleOpen}
                        />
                    </View>
                }
            </View>

        </View>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        form: {
            flex: 1,
            backgroundColor: colors.white,
            paddingHorizontal: 30,
            paddingVertical: 20,
            borderRadius: 4,
            overflow: 'hidden'
        },
        decoration: {
            position: 'absolute',
            width: '200%',
            top: 0,
            left: 0,
            height: 5
        },
        title: {
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center'
        },
        underTitle: {
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center',
            marginBottom: 12
        },
        text: {
            flex: 1,
            marginTop: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            decoration: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '200%',
                width: 5
            }
        }
    }
)