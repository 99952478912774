import { Icons } from "assets/icons";
import axios from "axios";
import { Button, ImageScaler, PlatformWebview, SText } from "components";
import { DocumentViewTypes, QueryKeys } from "enums";
import { useStyle } from "hooks";
import { Document } from "models";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Platform, View } from "react-native";
import { useQuery } from "react-query";
import PDFReader from 'rn-pdf-reader-js';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ApiUtils, DocumentUtils, QueryUtils } from "utils";
import StorageUtils from "utils/StorageUtils";
import BaseModal from "./BaseModal";


export default ({ settings }) => {

    const styles = useStyle()
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);
    const { triggerLoading } = useContext(StoreContext);

    const [source, setSource] = useState<any>();
    const [viewType, setViewType] = useState<DocumentViewTypes>();

    const [downloading, setDownloading] = useState(false);
    const download = async () => {
        setDownloading(true);
        const extension = (settings.extension ? '.' + settings.extension : (settings.mime_type ? '.' + settings.mime_type.split('/')[1] : '.txt'));
        const filename = (settings.name.indexOf('.') < 0) ? settings.name + extension : settings.name;
        await DocumentUtils.downloadDocument(settings, filename);
        setDownloading(false);
    }

    const { refetch: refetchDocuments } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const [removing, setRemoving] = useState(null);
    const removeDocument = async (document: Document) => {

        setRemoving(document.id);
        const response = await DocumentUtils.deleteDocument(document);
        if (response.success == true) {
            await refetchDocuments();
            triggerLoading();
            hideModal();
        } else {
            setRemoving(null);
        }

    }

    const getViewType = (mime: string): DocumentViewTypes => {

        let type;
        if (mime == 'png' ||
            mime == 'jpg' ||
            mime == 'gif' ||
            mime == 'jpeg' ||
            mime == 'svg') {

            type = DocumentViewTypes.image;

        } else if (mime == 'pdf') {

            type = DocumentViewTypes.pdf

        // } else if (mime == 'plain; charset=UTF-8') {

        //     type = DocumentViewTypes.txt

        } else {

            type = DocumentViewTypes.web;

        }

        console.log({mime, type});
        

        return type;
    }


    useEffect(() => {
        const loadDocument = async () => {
            let dataUrl: string = StorageUtils.getSessionStorageItem(settings.id);
            
            if (dataUrl) {

                const response = await axios.get(dataUrl, { responseType: "blob" })
                setViewType(getViewType(response.headers['content-type'].split('/')[1]));
                setSource(dataUrl);

            } else {

                console.log(settings.link.includes('customer/tenant/document') ? 'json' : 'blob');                

                const headers = await ApiUtils.getApiHeaders();
                const response = await axios.get(settings.link, {
                    headers,
                    responseType: settings.link.includes('customer/tenant/document') ? 'json' : 'blob'
                })


                if (settings.link.includes('customer/tenant/document')) {

                    //kantoorDocument
                    const mime_type = response.data.data.mime_type;
                    const data = response.data.data.content.data.data;
                    //load blob url
                    dataUrl = URL.createObjectURL((await axios.get(`data:${mime_type};base64,${data}`, { responseType: "blob" })).data);
                    setSource(`data:${mime_type};base64,${data}`);
                    setViewType(getViewType(mime_type.split('/')[1]));
                    StorageUtils.setSessionStorageItem(settings.id, dataUrl);

                } else if (Platform.OS == 'web') {

                    //load blob url
                    dataUrl = URL.createObjectURL(response.data);
                    setSource(dataUrl);
                    setViewType(getViewType(response.headers['content-type'].split('/')[1]));
                    StorageUtils.setSessionStorageItem(settings.id, dataUrl);

                } else {

                    //load base64 data
                    const fileReaderInstance = new FileReader();
                    fileReaderInstance.readAsDataURL(response.data);
                    fileReaderInstance.onload = async () => {
                        const base64string = fileReaderInstance.result.replace('application/octet-stream', response.headers['content-type']);
                        const blobData = await axios.get(base64string, { responseType: "blob" });
                        const dataUrl = URL.createObjectURL(blobData.data);
                        setSource(base64string);
                        setViewType(getViewType(response.headers['content-type'].split('/')[1]));
                        StorageUtils.setSessionStorageItem(settings.id, dataUrl);
                    }

                }

            }
        };
        loadDocument();
    }, [])

    const renderDocument = () => {

        switch (viewType) {
            case DocumentViewTypes.image:
                return <ImageScaler source={source} style={{ alignSelf: "center", width: '100%', flex: 1, marginBottom: 20 }} />

            case DocumentViewTypes.web:
                return <PlatformWebview sandbox={''} url={source} style={{ flex: 1, width: 'auto', borderRadius: 8 }} />

            case DocumentViewTypes.pdf:
                if (Platform.OS != 'web'){
                    return <PDFReader withPinchZoom={true} withScroll={true} noLoader source={{ base64: source }} style={{ flex: 1, width: 'auto', borderRadius: 8 }} />
                } else {
                    return <PlatformWebview url={source} style={{ flex: 1, width: 'auto', borderRadius: 8 }} />
                }                

            case DocumentViewTypes.txt:
                return <SText>{DocumentUtils.atob(source)}</SText>

        }
    }



    return (
        <BaseModal
            style={[
                scopedStyles('modal'),
                { minHeight: Dimensions.get('window').height * 0.9 },
                (viewType != DocumentViewTypes.web || viewType != DocumentViewTypes.pdf) && {
                    paddingTop: 20,
                    paddingHorizontal: 20
                }
            ]}
            hideModal={settings.hide}
        >
            <View style={styles('fullscreen')}>
                {(!source) ? (
                    <View style={[styles('fullscreen'), styles('center')]}>
                        <ActivityIndicator size={40} color={colors.primary} />
                    </View>
                ) : (
                    renderDocument()
                )}
            </View>

            <View style={{ flexDirection: 'row', width: '100%', height: 100, alignItems: "center", justifyContent: "flex-end" }}>
                {settings.deletable &&
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            loading={removing === settings.id}
                            label={'#action_remove'}
                            type='error'
                            onPress={() => removeDocument(settings)}
                            icon={(color) => <Icons.Delete_trash size={16} color={color} />}
                            style={{ marginRight: 8 }}
                        />
                    </View>
                }
                <View style={{ flexDirection: "row" }}>
                    <Button
                        loading={downloading}
                        label="#download"
                        type="secondary"
                        style={{ marginRight: 12 }}
                        onPress={download}
                    />
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Button
                        label='#close'
                        type={"action"}
                        onPress={() => settings.hide ? settings.hide() : hideModal()}
                    />
                </View>
            </View>

        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '90%',
            marginTop: '5%',
            marginBottom: 0
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minHeight: 0
            },
        }
    }
)