import { useEffect, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import WebView from "react-native-webview";
import { colors } from "styles";

interface props {
    html?: any
    url?: string,
    style?: any
    replaceVars?: any[],
    onLoad?: (e: any) => void,
    sandbox?: string
}

export default ({ html, url, style, replaceVars, sandbox, onLoad }: props) => {

    const providedStyle = (Array.isArray(style)) ? [scopedStyles.container, ...style] : [scopedStyles.container, style];
    const [frame, setFrame] = useState(null);

    useEffect(() => { transformHtml() }, [html])

    const transformHtml = async () => {

        let nFrame = (<></>);
        if (Platform.OS == 'web') {

            let nHtml = html || '';
            if (replaceVars) replaceVars.map((value) => {
                nHtml = nHtml.replace(value[0], value[1]);
            });

            nFrame = (
                <iframe
                    sandbox={sandbox}
                    srcDoc={html && nHtml}
                    src={url && url}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    onLoad={onLoad && onLoad}
                />
            );

        } else {

            let nHtml = html;
            if (replaceVars) replaceVars.map((value) => {
                nHtml = nHtml.replace(value[0], value[1]);
            });            

            nFrame = (
                <WebView
                    source={html ? { html: nHtml } : { uri: url }}
                    style={{ width: '100%', height: '100%', borderWidth: 0 }}
                    containerStyle={{ width: '100%', height: '100%', borderWidth: 0 }}
                    javaScriptEnabled={!sandbox || sandbox.includes('allow-scripts')}
                    domStorageEnabled={!sandbox}
                    enabled
                    onLoad={onLoad && onLoad}
                />
            );

        }

        setFrame(nFrame);
    }

    return (
        <View style={providedStyle}>
            {frame && frame}
        </View>
    )

}

const scopedStyles = StyleSheet.create({
    container: {
        width: '100%',
        flex: 1,
        backgroundColor: colors.white,
        overflow: "hidden"
    }
})
